<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Season Tickets</h1>
        </v-col>
        <v-col>
          <div class="input-container d-flex align-center">
            <v-text-field
              label="Search Season Tickets"
              v-model="searchTerm"
              outlined
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-menu
              offset-y
              left
              transition="slide-y-transition"
              :close-on-click="searchFilters.menu.closeOnClick"
              :close-on-content-click="searchFilters.menu.closeOnContentClick"
            >
              <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      icon
                      v-on="{ ...onTooltip, ...onMenu }"
                      v-bind="attrsMenu"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <span>Filter Search</span>
                </v-tooltip>
              </template>
              <v-card
                elevation="0"
                color="grey lighten-5"
                style="min-width: 200px"
              >
                <v-card-subtitle class="d-flex align-center">
                  <div class="font-weight-bold">Filters</div>
                  <v-spacer></v-spacer>
                  <v-btn outlined x-small>Reset</v-btn>
                </v-card-subtitle>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.createTicketDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Season Ticket</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-data-table
            :headers="tableHeaders"
            :items="tickets"
            no-data-text="No Season Tickets"
          >
            <template v-slot:item.ticket_type="{ item }">
              <v-chip small label>{{ item.ticket_type.title }}</v-chip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="new Date().toISOString().substring(0, 10) > item.valid_to"
                small
                label
                color="red"
                text-color="white"
                >Expired</v-chip
              >
              <v-chip
                v-else
                small
                label
                :color="getSeasonPassStatusColor(item.status)"
                >{{ getSeasonPassStatusLabel(item.status) }}</v-chip
              >
            </template>
            <template v-slot:item.total_holders="{ item }">
              {{ item.holders.length }}
            </template>
            <template v-slot:item.valid_to="{ item }">
              {{ item.formatted_dates.valid_to }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'module-craigtoun-tickets-individual',
                      params: { ticketId: item.id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="2">
          <v-card class="text-center blue lighten-4 mb-6" outlined>
            <v-card-text>
              <div class="text-subtitle-1">Total</div>
              <div class="text-h4">{{ total_tickets.total }}</div>
            </v-card-text>
          </v-card>
          <v-card class="text-center green lighten-4 mb-6" outlined>
            <v-card-text>
              <div class="text-subtitle-1">
                New<br />
                This Month
              </div>
              <div class="text-h4">{{ total_tickets.month }}</div>
            </v-card-text>
          </v-card>
          <v-card class="text-center red lighten-4" outlined>
            <v-card-text>
              <div class="text-subtitle-1">
                Expiring Within<br />
                4 Weeks
              </div>
              <div class="text-h4">{{ total_tickets.expiring }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <create-ticket-dialog ref="createTicketDialog" />
  </div>
</template>

<script>
import CreateTicketDialog from "./components/CreateTicketDialog.vue";
import { craigtoun } from "../../mixins";

export default {
  mixins: [craigtoun],

  components: {
    CreateTicketDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Season Tickets",
          disabled: true,
        },
      ],
      searchTerm: "",
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
      tableHeaders: [
        { text: "Customer Name", value: "customer.full_name" },
        { text: "Pass Type", value: "ticket_type" },
        { text: "Status", value: "status" },
        { text: "Total Holders", value: "total_holders" },
        { text: "Expiry Date", value: "valid_to" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    tickets() {
      let tickets = this.$store.getters["craigtoun/ticketsStore/all"];

      if (this.searchTerm !== "") {
        tickets = tickets.filter((t) => {
          const customerName = t.customer.full_name.toLowerCase();
          const ticketType = t.ticket_type.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            customerName.includes(searchTerm) ||
            ticketType.includes(searchTerm) ||
            t.holders.some((h) => {
              if (h.full_name === null) {
                return false;
              }
              const fullName = h.full_name.toLowerCase();

              return fullName.includes(searchTerm);
            })
          );
        });
      }

      return tickets;
    },

    total_tickets: function () {
      let tickets = this.$store.getters["craigtoun/ticketsStore/all"];
      let date = new Date();

      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      var fourWeeksInFuture = new Date(new Date().setDate(date.getMonth() + 1));

      let tickets_array = {};

      let total_valid = tickets.filter((o) => {
        const validTo = new Date(o.valid_to);
        const validFrom = new Date(o.valid_from);
        return validFrom <= date && validTo >= date;
      });

      let tickets_in_month = tickets.filter((o) => {
        const itemDate = new Date(o.created_at);
        return itemDate >= firstDay && itemDate <= lastDay;
      });

      let expiring = tickets.filter((o) => {
        const validTo = new Date(o.valid_to);
        const validFrom = new Date(o.valid_from);
        return validFrom <= date && validTo >= fourWeeksInFuture;
      });

      tickets_array["total"] = total_valid.length;
      tickets_array["month"] = tickets_in_month.length;
      tickets_array["expiring"] = expiring.length;

      return tickets_array;
    },
  },
};
</script>
