<template>
  <div>
    <v-switch
      :label="principalSwitchLabel"
      v-model="is_customer"
      inset
      color="success"
      class="mt-0"
      v-if="isFirst"
      :false-value="false"
      :true-value="true"
    ></v-switch>
    <v-text-field
      label="Full Name *"
      :value="fields.full_name"
      type="text"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('full_name')"
      :error-messages="errors['full_name']"
      :disabled="fields.is_guest || is_customer"
      @input="update('full_name', $event)"
    ></v-text-field>
    <v-file-input
      label="Ticket Holder's Photo"
      accept="image/png, image/jpeg"
      :value="fields.photo"
      placeholder="Pick a photo"
      prepend-icon="mdi-camera"
      outlined
      :disabled="fields.is_guest"
    ></v-file-input>
    <v-switch
      label="Is a Guest?"
      :value="fields.is_guest"
      inset
      color="success"
      class="mt-0"
      :disabled="is_customer"
      :error="errors.hasOwnProperty('is_guest')"
      :error-messages="errors['is_guest']"
      @change="update('is_guest', $event)"
      :false-value="false"
      :true-value="true"
    ></v-switch>
    <v-switch
      label="Is an Additional Ticket?"
      :value="fields.is_additional"
      inset
      color="success"
      class="mt-0"
      :disabled="is_customer"
      :error="errors.hasOwnProperty('is_additional')"
      :error-messages="errors['is_additional']"
      @change="update('is_additional', $event)"
      :false-value="false"
      :true-value="true"
    ></v-switch>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: Object,
    },
    customerId: {
      type: Number,
      required: false,
    },
    isFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        full_name: null,
        is_guest: false,
        is_additional: false,
        is_principal: false,
      },
      is_customer: false,
      errors: {},
    };
  },

  created() {
    this.fields = _.clone(this.value);
  },

  computed: {
    customer() {
      if (this.customerId) {
        let customers = this.$store.getters["craigtoun/customersStore/all"];

        return customers.find((c) => {
          return c.id === this.customerId;
        });
      } else {
        return null;
      }
    },

    principalSwitchLabel() {
      if (this.customer) {
        return `Is this ticket for ${this.customer.full_name}?`;
      } else {
        return null;
      }
    },

    save() {
      return this.fields;
    },
  },

  mounted() {
    if (this.isFirst) {
      this.fields.is_principal = true;
      this.is_customer = true;
    }
  },

  watch: {
    customer(newCustomer) {
      this.update("full_name", newCustomer.full_name);
    },

    is_customer(value) {
      if (value && this.customer) {
        this.update("full_name", this.customer.full_name);
      }
    },
  },

  methods: {
    update(key, value) {
      let fields = _.clone(this.fields);
      fields[key] = value;

      this.fields = fields;

      this.$emit("input", {
        fields,
        index: this.index,
      });
    },
  },
};
</script>
