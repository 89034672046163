<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? (isRenewal ? "Renew" : "Edit") : "Add a new" }} Season
        Ticket
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="ticket-form">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step step="1" :complete="step > 1"
                >Basic Details</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step step="2">Ticket Holders</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-select
                  label="Customer *"
                  v-model="fields.customer_id"
                  :items="customers"
                  item-value="id"
                  item-text="full_name"
                  outlined
                  background-color="white"
                  class="mt-1"
                  :error="errors.hasOwnProperty('customer_id')"
                  :error-messages="errors['customer_id']"
                ></v-select>
                <v-select
                  label="Ticket Type *"
                  v-model="fields.ticket_type_id"
                  :items="types"
                  item-value="id"
                  item-text="title"
                  outlined
                  background-color="white"
                  :error="errors.hasOwnProperty('ticket_type_id')"
                  :error-messages="errors['ticket_type_id']"
                ></v-select>
                <v-text-field
                  label="Number of Tickets *"
                  v-model="fields.total_tickets"
                  type="number"
                  min="1"
                  outlined
                  background-color="white"
                  @input="updateTotalTickets"
                ></v-text-field>
                <v-text-field
                  label="Valid From *"
                  v-model="fields.valid_from"
                  type="date"
                  outlined
                  background-color="white"
                  :error="errors.hasOwnProperty('valid_from')"
                  :error-messages="errors['valid_from']"
                ></v-text-field>
                <v-text-field
                  label="Valid To *"
                  v-model="fields.valid_to"
                  type="date"
                  outlined
                  background-color="white"
                  :error="errors.hasOwnProperty('valid_to')"
                  :error-messages="errors['valid_to']"
                ></v-text-field>
                <v-text-field
                  label="Cost *"
                  type="number"
                  min="0.00"
                  step="1.00"
                  v-model="fields.cost"
                  prefix="£"
                  outlined
                  background-color="white"
                  :error="errors.hasOwnProperty('cost')"
                  :error-messages="errors['cost']"
                ></v-text-field>
                <v-select
                  label="Status *"
                  v-model="fields.status"
                  :items="seasonPassStatuses"
                  item-value="value"
                  item-text="label"
                  outlined
                  background-color="white"
                  :error="errors.hasOwnProperty('status')"
                  :error-messages="errors['status']"
                ></v-select>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card
                  outlined
                  v-for="(holder, index) in fields.holders"
                  :key="index"
                  class="mb-6"
                >
                  <v-card-subtitle
                    >Ticket Holder #{{ index + 1
                    }}{{
                      parseInt(index) === 0 ? ": Principal Ticket Holder" : ""
                    }}</v-card-subtitle
                  >
                  <v-divider></v-divider>
                  <v-card-text>
                    <ticket-holder-fields
                      :value="fields.holders[index]"
                      :customerId="fields.customer_id"
                      :isFirst="parseInt(index) === 0"
                      :index="index"
                      :key="index"
                      :ref="'ticketHolderFields' + index"
                      @input="ticketHolderUpdate"
                    />
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="step = 1" v-show="step === 2">Back</v-btn>
        <v-btn
          color="info"
          text
          @click="progress"
          v-show="step === 1"
          :disabled="canProgress === false"
          >Next</v-btn
        >
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="ticket-form"
          v-show="step === 2"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TicketHolderFields from "./TicketHolderFields.vue";
import _ from "lodash";
import { craigtoun } from "../../../mixins";

export default {
  mixins: [craigtoun],

  components: {
    TicketHolderFields,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      step: 1,
      ticket: {},
      isRenewal: false,
      fields: {
        ticket_type_id: null,
        customer_id: null,
        total_tickets: 1,
        valid_from: null,
        valid_to: null,
        cost: null,
        identifier: null,
        status: null,
        holders: [],
      },
      statuses: ["Past", "Current", "Future"],
      errors: {},
      sampleHolder: {
        full_name: null,
        photo: null,
        is_guest: false,
        is_additional: false,
        is_principal: false,
      },
    };
  },

  created() {
    const holder = _.clone(this.sampleHolder);
    this.fields.holders.push(holder);
  },

  computed: {
    types() {
      return this.$store.getters["craigtoun/ticketsStore/types"];
    },

    customers() {
      return this.$store.getters["craigtoun/customersStore/all"];
    },

    customer() {
      if (this.fields.customer_id) {
        let customers = this.$store.getters["craigtoun/customersStore/all"];

        return customers.find((c) => {
          return c.id === this.fields.customer_id;
        });
      } else {
        return null;
      }
    },

    canProgress() {
      if (this.fields.customer_id !== null) {
        return true;
      } else {
        return false;
      }
    },

    totalTickets() {
      return this.fields.total_tickets;
    },

    principalSwitchLabel() {
      if (this.customer) {
        return `Is this ticket for ${this.customer.full_name}?`;
      } else {
        return null;
      }
    },
  },

  methods: {
    open(ticket = null, isRenewal = false) {
      if (ticket !== null) {
        this.ticket = ticket;
        this.fields.ticket_type = ticket.ticket_type;
        this.fields.cost = ticket.cost;
        this.fields.status = ticket.status;
        this.isEditing = true;

        if (isRenewal) {
          this.isRenewal = true;

          const validTo = new Date(ticket.valid_to);
          const day = 60 * 60 * 24 * 1000;
          const year = day * 365;

          const validFrom = new Date(validTo + day);
          const newValidTo = new Date(validFrom + year);

          this.fields.valid_from = validFrom.toISOString().split("T")[0];
          this.fields.valid_to = newValidTo.toISOString().split("T")[0];
        } else {
          this.fields.valid_from = ticket.valid_from;
          this.fields.valid_to = ticket.valid_to;
        }
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.ticket = {};
      this.isRenewal = false;
      this.fields.ticket_type_id = null;
      this.fields.customer_id = null;
      this.fields.valid_from = null;
      this.fields.valid_to = null;
      this.fields.cost = null;
      this.fields.identifier = null;
      this.fields.status = null;
      this.fields.holders = [];
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.ticketId = this.ticket.id;
      }

      this.$store
        .dispatch("craigtoun/ticketsStore/saveTicket", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    progress() {
      this.step = 2;
      this.$refs.formWrapper.scrollTop = 0;
    },

    updateTotalTickets() {
      if (this.fields.total_tickets !== this.fields.holders.length) {
        if (this.fields.total_tickets > this.fields.holders.length) {
          const diff = this.fields.total_tickets - this.fields.holders.length;

          for (let i = 0; i < diff; i++) {
            const holder = _.clone(this.sampleHolder);
            let holders = _.clone(this.fields.holders);
            holders.push(holder);

            this.fields.holders = holders;
          }
        } else {
          const diff = this.fields.holders.length - this.fields.total_tickets;
          let holders = _.clone(this.fields.holders);

          for (let i = 0; i < diff; i++) {
            holders.pop();
          }

          this.fields.holders = holders;
        }
      }
    },

    ticketHolderUpdate(result) {
      this.fields.holders[result.index] = result.fields;
    },
  },
};
</script>
